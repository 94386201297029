import { config } from '@/model/config'

export const routerCatalog = {
	home: '/',
	login: '/login',
	logout: '/logout',

	org: {
		root: '/_/projects',
	},

	apiServer: {
		idpInit: `${config.apiUrl}/idp/init`,
		idpSignIn: `${config.apiUrl}/idp/sign-in`,
		idpSignOut: `${config.apiUrl}/idp/sign-out`,

		me: `${config.apiUrl}/me`,

		signing: {
			signApprove: `${config.apiUrl}/v1/signing-codes/sign-approve`,
		},

		plugins: {
			list: `${config.apiUrl}/v1/plugins/list`,
		},

		projects: {
			create: `${config.apiUrl}/projects/create`,
			list: `${config.apiUrl}/projects/list`,
			detail: `${config.apiUrl}/projects/detail`,

			save: `${config.apiUrl}/projects/save`,
			prompt: `${config.apiUrl}/projects/prompt`,

			archive: `${config.apiUrl}/projects/archive`,
			rebase: `${config.apiUrl}/projects/rebase`,
			deploy: `${config.apiUrl}/projects/deploy`,
			download: (projectId: string, sessionId?: string) =>
				`${config.apiUrl}/projects/download?id=${projectId}${sessionId ? `&sessionId=${sessionId}` : ''}`,

			codepush: `${config.apiUrl}/projects/codepush`,
			codepushInstall: `${config.apiUrl}/projects/codepush-install`,

			edits: {
				create: `${config.apiUrl}/projects/edits/sessions/create`,
				list: `${config.apiUrl}/projects/edits/sessions/list`,

				sandboxInit: `${config.apiUrl}/projects/edits/sessions/sandbox`,
				sandboxCommand: `${config.apiUrl}/projects/edits/sessions/command`,

				codebaseIndexing: `${config.apiUrl}/projects/edits/codebase-indexing`,

				prompt: `${config.apiUrl}/projects/edits/prompt`,
			},

			repository: {
				branches: `${config.apiUrl}/projects/repository/branches`,
			},
		},

		internal: {
			projects: `${config.apiUrl}/v1/internal/projects`,
			editor: {
				recipes: `${config.apiUrl}/v1/internal/editor/recipes`,
			},
		},
	},

	selfcareServer: {
		home: `${config.selfcareUrl}`,
		profile: `${config.selfcareUrl}/profile`,

		projectCreateWillBackRedirect(projectId: string, projectName: string, orgId: string | null, editSessionId: string | null | undefined): string {
			const projectCreateUrl = new URL(config.selfcareUrl)
			projectCreateUrl.pathname = `/org/${orgId ?? '_'}/create-project`
			projectCreateUrl.searchParams.set('studioProjectId', projectId)
			projectCreateUrl.searchParams.set('studioProjectName', projectName)
			projectCreateUrl.searchParams.set('engineVersion', 'v0104')

			if (editSessionId) {
				projectCreateUrl.searchParams.set('editSessionId', editSessionId)
			}

			return projectCreateUrl.toString()
		},

		projectDetail(orgId: string, projectGroupSlug: string): string {
			const projectDetailUrl = new URL(config.selfcareUrl)
			projectDetailUrl.pathname = `/org/${orgId}/p/${projectGroupSlug}/`
			projectDetailUrl.searchParams.set('welcome', '1')
			return projectDetailUrl.toString()
		},

		orgGithubConnect(orgId: string, backlink: string, repositoryNameSuggestion?: string): string {
			const connectUrl = new URL(config.selfcareUrl)
			connectUrl.pathname = `/org/${orgId}/github-connect`

			if (repositoryNameSuggestion) {
				connectUrl.searchParams.set('suggestedRepositoryName', repositoryNameSuggestion)
			}

			connectUrl.searchParams.set('backlink', backlink)
			return connectUrl.toString()
		},
	},
}
